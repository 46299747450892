

export const log = async (token, LogText) => {
    if (process.env.REACT_APP_LOG === 0)
        return;

    const dashboard = await fetch(`${process.env.REACT_APP_API_ROOT}/Log`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ LogText })
        });

    await dashboard.json();
};
