import xtiva_logo from "../fonts/svg/xtiva_logo.png";
import styled from "styled-components";
import {AccountLogout, Dashboard} from "../fonts/iconic";
import {ReactComponent as PieChartMd} from "../fonts/iconic/pie-chart-md.svg";
import React, {useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";


export const SideMenu = ({ selected, hasAnalysis }) => {
    const { user } = useAuth0();

  return (<div style={{ width: 235, backgroundColor: 'rgb(39,39,50)', position: 'relative' }}>
              <div style={{ padding: '10px 15px', borderBottom: '1px solid rgb(58,58,71)'}}>
                  <img src={xtiva_logo} width={125}/>
              </div>

              <div style={{ padding: 15, color: 'white', borderBottom: '1px solid rgb(58,58,71)' }}>
                  <div style={{ fontSize: 12 }}>Welcome</div>
                  <div style={{ fontSize: 14, fontWeight: 'bold', marginTop: 3 }}>{user.name}</div>
              </div>

              <div style={{ color: 'white', paddingTop: 15 }}>
                  <PrimaryAction href={`/`} selected={selected === 0}>
                      <DashboardIcon selected={selected === 0}/>
                      <PrimaryText selected={selected === 0}>Dashboard</PrimaryText>
                  </PrimaryAction>

                  {hasAnalysis && <PrimaryAction href={`/analysis`} selected={selected === 1}>
                      <AnalysisIcon selected={selected === 1}/>
                      <PrimaryText selected={selected === 1}>Analysis</PrimaryText>
                  </PrimaryAction>}
              </div>
              <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, padding: '10px 0', borderTop: '.1rem solid #3a3a47' }}>
                  <SecondaryAction href="/logout">
                      <Logout/>
                      <SecondaryText>Logout</SecondaryText>
                  </SecondaryAction>
              </div>
          </div>);

};


const PrimaryAction = styled.a`
    color: white;
    text-decoration: none;
    height: 45px;
    display: flex;
    align-items: center;  
    background-color: ${props => props.selected ? '#3a3a47' : 'transparent'};
`;


const DashboardIcon = styled(Dashboard)`
    margin-left: 12px; 
    height: 18px;
    
    .iconic-property-fill {
        fill: ${props => props.selected ? '#33b6e3' : 'white'};
    }

    .iconic-property-stroke {
        stroke: ${props => props.selected ? '#33b6e3' : 'white'};
    }
    
    ${PrimaryAction}:hover & {
        .iconic-property-fill {
            fill: #33b6e3;
        }
    
        .iconic-property-stroke {
            stroke: #33b6e3;
        }
    }
`;


const AnalysisIcon = styled(PieChartMd)`
    margin-left: 12px; 
    height: 18px;

    .iconic-property-fill {
        fill: ${props => props.selected ? '#33b6e3' : 'white'};
    }

    .iconic-property-stroke {
        stroke: ${props => props.selected ? '#33b6e3' : 'white'};
    }
    
    ${PrimaryAction}:hover & {
        .iconic-property-fill {
            fill: #33b6e3;
        }
    
        .iconic-property-stroke {
            stroke: #33b6e3;
        }
    }
`;

const PrimaryText = styled.div`
    text-transform: uppercase;
    font-size: 10px;
    margin-left: 12px;
    
    color: ${props => props.selected ? '#33b6e3' : 'white'};
    
    ${PrimaryAction}:hover & {
        color: #33b6e3;
    }
`;

const SecondaryAction = styled.a`
    color: #949494;
    text-decoration: none;
    height: 45px;
    display: flex;
    align-items: center;  
`;

const Logout = styled(AccountLogout)`
    margin-left: 12px;
    height: 18px;
    
    .iconic-property-fill {
        fill: #949494;
    }

    .iconic-property-stroke {
        stroke: #949494;
    }
    
    ${SecondaryAction}:hover & {
        .iconic-property-fill {
            fill: #33b6e3;
        }
    
        .iconic-property-stroke {
            stroke: #33b6e3;
        }
    }
`;

const SecondaryText = styled.div`
    text-transform: uppercase;
    font-size: 12px;
    margin-left: 12px;
    
    ${SecondaryAction}:hover & {
        color: #33b6e3;
    }
`;









