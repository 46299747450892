import React from "react";
import { ReactComponent as LeftChevronMd } from "./chevron-left-md.svg";
import { ReactComponent as PlusThinMd } from "./plus-thin-md.svg";
import { ReactComponent as NetworkMd } from "./network-md.svg";
import { ReactComponent as PeopleMd } from "./people-md.svg";
import { ReactComponent as EllipsesMd } from "./ellipses-md.svg";
import { ReactComponent as SettingsMd } from "./settings-md.svg";
import { ReactComponent as EnvelopeClosedMd } from "./envelope-closed-md.svg";
import { ReactComponent as CheckThinMd } from "./check-thin-md.svg";
import { ReactComponent as LockLockedMd } from "./lock-locked-md.svg";
import { ReactComponent as XThinMd } from "./x-thin-md.svg";
import { ReactComponent as PersonMd } from "./person-genderless-md.svg";
import { ReactComponent as AccountLogoutMd } from "./account-logout-md.svg";
import { ReactComponent as ToolsMd } from "./tools-md.svg";
import { ReactComponent as ArrowRightLineLargeObliqueMd } from "./arrow-right-line-large-oblique-md.svg";
import { ReactComponent as ArrowRightLineMd } from "./arrow-right-line-large-oblique-md.svg";
import { ReactComponent as ArrowLeftLineMd } from "./arrow-left-line-large-oblique-md.svg";
import { ReactComponent as CaretBottomMd } from "./caret-bottom-md.svg";
import { ReactComponent as CaretRightMd } from "./caret-right-md.svg";
import { ReactComponent as FunnelMd } from "./funnel-md.svg";
import { ReactComponent as PersonGenderlessMd } from "./person-genderless-md.svg";
import { ReactComponent as DollarMd } from "./dollar-md.svg";
import { ReactComponent as BuildingMd } from "./building-md.svg";
import { ReactComponent as RocketMd } from "./rocket-md.svg";
import { ReactComponent as MonitorMd } from "./monitor-md.svg";
import { ReactComponent as MagnifyingGlassMd } from "./magnifying-glass-md.svg";
import { ReactComponent as InfoMd } from "./info-md.svg";
import { ReactComponent as PulseThinMd } from "./pulse-thin-md.svg";
import { ReactComponent as PieChartMd } from "./pie-chart-md.svg";
import { ReactComponent as DashboardMd } from "./dashboard-md.svg";



export const LeftChevron = (props) => <LeftChevronMd {...props} />;
export const PlusThin = (props) => <PlusThinMd {...props} />;
export const Network = (props) => <NetworkMd {...props} />;
export const People = (props) => <PeopleMd {...props} />;
export const Ellipses = (props) => <EllipsesMd {...props} />;
export const Settings = (props) => <SettingsMd {...props} />;
export const Check = (props) => <CheckThinMd {...props} />;
export const LockLocked = (props) => <LockLockedMd {...props} />;
export const EnvelopeClosed = (props) => <EnvelopeClosedMd {...props} />;
export const XThin = (props) => <XThinMd {...props} />;
export const Person = (props) => <PersonMd {...props} />;
export const AccountLogout = (props) => <AccountLogoutMd {...props} />;
export const Tools = (props) => <ToolsMd {...props} />;
export const ArrowRightLine = (props) => <ArrowRightLineMd {...props} />;
export const ArrowRightLineLargeOblique = (props) => <ArrowRightLineLargeObliqueMd {...props} />;
export const PersonGenderless = (props) => <PersonGenderlessMd {...props} />;
export const Dollar = (props) => <DollarMd {...props} />;
export const Building = (props) => <BuildingMd {...props} />;
export const Rocket = (props) => <RocketMd {...props} />;
export const ArrowLeftLine = (props) => <ArrowLeftLineMd {...props} />;
export const Monitor = (props) => <MonitorMd {...props} />;
export const CaretBottom = (props) => <CaretBottomMd {...props} />;
export const CaretRight = (props) => <CaretRightMd {...props} />;
export const MagnifyingGlass = (props) => <MagnifyingGlassMd {...props} />;
export const Funnel = (props) => <FunnelMd {...props} />;
export const Info = (props) => <InfoMd {...props} />;
export const PulseThin = (props) => <PulseThinMd {...props} />;
export const PieChart = (props) => <PieChartMd {...props} />;
export const Dashboard = (props) => <DashboardMd {...props} />;

