import styled from "styled-components";

export const AnalyticsPage = styled.div`
    display: flex;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right:0;
`;


export const ResponsiveContainer = styled.div`
    position: relative;
    width: calc(100% - 255px);
    height: calc(100% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
`;

export const ResponsiveIframe = styled.iframe`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
`;
