import './App.css';
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import { Route, Switch} from "react-router-dom";
import {LoadingPage} from "./views/LoadingPage";
import {DashboardPage} from "./views/DashboardPage";
import {AnalysisPage} from "./views/AnalysisPage";
import logo from "./fonts/svg/logo-auth0.png";

const RenderLogo = () => (<img alt={logo} src={logo}/>);
const Logout = () => {
    const { logout } = useAuth0();

    useEffect(() => {
        logout({
            returnTo: window.location.origin
        });
    }, [logout]);

    return <div style={{ padding: 20 }}>OK</div>
};


const App = () => {
  return (<Switch>
            <Route exact path="/analysis" component={AnalysisPage}/>
            <Route exact path="/logout" component={Logout}/>
            <Route exact path="/logo" component={RenderLogo}/>
            <Route exact path="/" component={DashboardPage}/>
        </Switch>);
};

export default withAuthenticationRequired(App, {
  onRedirecting: () => <LoadingPage />,
});

