import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {log} from "../api";
import {AnalyticsPage, ResponsiveContainer, ResponsiveIframe} from "../components";
import {SideMenu} from "../components/SideMenu";
import {Spinner} from "../components/Spinner";

export const AnalysisPage = () => {
    const [authorLink, setAuthorLink] = useState("");
    const [loading, setLoading] = useState(true);

    const {getIdTokenClaims} = useAuth0();

    useEffect(() => {
        const generateConsoleUrl = async () => {
            const tokenClaims = await getIdTokenClaims({});

            const analyses = await fetch(`${process.env.REACT_APP_API_ROOT}/UserDashboards?analyses=true`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${tokenClaims.__raw}`
                    }
                });

            if (analyses.status !== 200) {
                setLoading(false);
                return;
            }

            let data = await analyses.json();
            if (data.analyses.length === 0) {
                window.location.href='/';
                setLoading(false);
                return;
            }

            const author = await fetch(`${process.env.REACT_APP_API_ROOT}/ConsoleUrl`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${tokenClaims.__raw}`
                    },
                    body: JSON.stringify({})
                });

            if (author.status === 200) {
                let data = await author.json();
                setAuthorLink(data.url);
            }

            setLoading(false);
            await log(tokenClaims.__raw, `Analysis Page -`);
        };
        generateConsoleUrl().then(e => {
        });
    }, [getIdTokenClaims]);


    return (<AnalyticsPage>
        <SideMenu selected={1} hasAnalysis={true}/>
        <ResponsiveContainer>
            {loading ?
                <div>
                    <Spinner/>
                    <span style={{ fontSize: 12 }}>Preparing the analysis</span>
                </div> :
                <ResponsiveIframe title="QS Author Console" frameBorder="0" src={authorLink} id="authorConsole"/>
            }
        </ResponsiveContainer>
    </AnalyticsPage>);
};
