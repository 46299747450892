import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {log} from "../api";
import {SideMenu} from "../components/SideMenu";
import {AnalyticsPage, ResponsiveContainer, ResponsiveIframe} from "../components";
import {Spinner} from "../components/Spinner";
import {embedDashboard} from "amazon-quicksight-embedding-sdk";
import styled from "styled-components";

export const DashboardPage = () => {
    const [dashboardLink, setDashboardLink] = useState("");
    const [loading, setLoading] = useState(true);
    const [hasAnalysis, setHasAnalysis] = useState(false);
    const { getIdTokenClaims, user } = useAuth0();

    useEffect(() => {

        const fetchDashboard = async () => {
            const tokenClaims = await getIdTokenClaims({});

            const dashboards = await fetch(`${process.env.REACT_APP_API_ROOT}/UserDashboards?dashboards=true&analyses=true`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${tokenClaims.__raw}`
                    }
                });

            if (dashboards.status !== 200) {
                setLoading(false);
                return;
            }

            let data = await dashboards.json();

            if (data.dashboards.length === 0) {
                setLoading(false);
                return;
            }

            const DashboardId = data.dashboards[0].DashboardId;
            const dashboard = await fetch(`${process.env.REACT_APP_API_ROOT}/DashboardUrl`,
                {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${tokenClaims.__raw}`
                },
                body: JSON.stringify({ DashboardId })
            });

            if (dashboard.status === 200) {
                let data = await dashboard.json();
                setDashboardLink(data.url);
                embedDashboard({
                    url: data.url,
                    container: document.getElementById("embeddingContainer"),
                    scrolling: "yes",
                    height: "AutoFit",
                    iframeResizeOnSheetChange: true,
                    width: "100%",
                    locale: "en-US",
                    footerPaddingEnabled: false,
                    selectedSheetChangeCallback: async (params) => {
                        await log(tokenClaims.__raw, `SHEET_CHANGE,${DashboardId},${user.email},,${params.selectedSheet.name}`);
                    },
                    printEnabled: false
                });
            }

            setHasAnalysis(data.analyses.length > 0);
            setLoading(false);

            await log(tokenClaims.__raw, `LOGIN,${DashboardId},${user.email},${document.hasFocus()},`);

            const recurring = async () => {
                await log(tokenClaims.__raw, `FOCUS,${DashboardId},${user.email},${document.hasFocus()},`);
            };
            setInterval( recurring, 60000)
        };
        fetchDashboard().then(e => {});

    }, [getIdTokenClaims]);

    return (<AnalyticsPage>
                <SideMenu selected={0} hasAnalysis={hasAnalysis}/>
                <ResponsiveContainer>
                    {loading &&
                        <div>
                            <Spinner/>
                            <span style={{ fontSize: 12 }}>Preparing the dashboard</span>
                        </div> }
                            <EmbeddedContainer id="embeddingContainer"
                                               style={{ visibility: loading ? 'hidden' : 'visible' }}/>
                </ResponsiveContainer>
            </AnalyticsPage>);
};

const EmbeddedContainer = styled.div`
    position:fixed;
    top:10px;
    bottom:10px;
    right:0px;
    left:250px;
    overflow-y: auto;
`;
